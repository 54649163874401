import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Amplify } from 'aws-amplify';
import App from './App';
import ApolloClient from './utils/apollo';
import config from './aws-config';
import './index.css';

Amplify.configure(config);

ReactDOM.render(
  <ApolloProvider client={ApolloClient as any}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);
