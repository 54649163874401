export default (data: RequestResponse) => {
  if (!data) return [];

  return data.listCompany.items
    .map((item) => ({
      ...item,
      key: item.id,
    }))
    .filter((item) => item.users.items.length > 0);
};
