import React, { useState, useRef } from 'react';
import {
  Table,
  Button,
  Popconfirm,
  Modal,
  Form,
  Input,
  message,
  Switch,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CSVReader from 'react-csv-reader';
import { useMutation, useQuery } from '@apollo/client';
import { GET_DOMAINS_LIST } from 'graphql/queries';
import { CREATE_WHITELIST_DOMAIN, DELETE_DOMAIN } from 'graphql/mutations';
import dataMapper from 'services/whitelist-service';
import Title from 'antd/lib/typography/Title';

const WhiteList = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const listColor = useRef<boolean>(true);
  const [form] = Form.useForm();
  const { loading, data, refetch } = useQuery(GET_DOMAINS_LIST, {
    variables: {
      blackList: !listColor.current,
    },
    onError: () => {
      message.error('Server Error');
    },
  });
  const [createDomain, { loading: createLoading }] = useMutation(
    CREATE_WHITELIST_DOMAIN,
    {
      refetchQueries: [
        {
          query: GET_DOMAINS_LIST,
          variables: { blackList: !listColor.current },
        },
      ],
    }
  );

  const [createDomainInBulk, { loading: bulkLoading }] = useMutation(
    CREATE_WHITELIST_DOMAIN
  );

  const [deleteDomain, { loading: deleteLoading }] = useMutation(
    DELETE_DOMAIN,
    {
      refetchQueries: [
        {
          query: GET_DOMAINS_LIST,
          variables: { blackList: !listColor.current },
        },
      ],
    }
  );

  const handleListColor = (checked: boolean) => {
    listColor.current = checked;
    refetch({ blackList: !listColor.current });
  };

  const handleFileUpload = async (data: any[]) => {
    await Promise.all(
      data.map((item) => {
        const [domain, description] = item;
        return createDomainInBulk({
          variables: {
            input: {
              domain,
              description,
              blacklisted: !listColor.current,
            },
          },
        });
      })
    );

    refetch();
  };

  const Columns = [
    {
      title: 'Organization Name',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Domain Name',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Action',
      render: (_: string, record: Domain) => {
        return (
          <Popconfirm
            placement="left"
            title="Please Confirm your choice"
            onConfirm={() => {
              deleteDomain({
                variables: {
                  input: {
                    id: record.id,
                  },
                },
              });
            }}
            okText="Confirm"
            cancelText="Cancel"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const mappedData = dataMapper(data);

  return (
    <>
      <Title level={3}>Manage Whitelist</Title>
      <Button
        onClick={() => setModalVisible(true)}
        type="primary"
        icon={<PlusOutlined />}
        className="unit-margin"
      >
        Add
      </Button>
      <CSVReader
        onFileLoaded={handleFileUpload}
        label="Upload CSV"
        inputStyle={{ display: 'none' }}
        cssLabelClass="upload-label"
        cssClass="upload-input"
      />
      <Switch
        defaultChecked
        onChange={handleListColor}
        className="unit-margin-sides"
        checkedChildren="white list"
        unCheckedChildren="black list"
      />
      <Table
        className="unit-margin"
        columns={Columns}
        dataSource={mappedData}
        loading={loading || createLoading || deleteLoading || bulkLoading}
        pagination={{
          total: mappedData.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultPageSize: 10,
          defaultCurrent: 1,
        }}
      />
      <Modal
        visible={modalVisible}
        title="Create a new domain"
        okText="Create"
        cancelText="Cancel"
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            createDomain({
              variables: {
                input: {
                  ...values,
                  blacklisted: !listColor.current,
                },
              },
            });
            setModalVisible(false);
          });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: 'public' }}
        >
          <Form.Item
            name="description"
            label="Organization Name"
            rules={[
              {
                required: true,
                message: 'Please input Organization Name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="domain"
            label="Domain Name"
            rules={[
              {
                required: true,
                message: 'Please input Domain Name',
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WhiteList;
