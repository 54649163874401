import { gql } from '@apollo/client';

export const CREATE_WHITELIST_DOMAIN = gql`
  mutation createDomainName($input: CreateDomainNameInput!) {
    createDomainName(input: $input) {
      id
      domain
      description
    }
  }
`;

export const DELETE_DOMAIN = gql`
  mutation DeleteDomainName($input: DeleteDomainNameInput!) {
    deleteDomainName(input: $input) {
      id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
