/* eslint-disable @typescript-eslint/naming-convention */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

type EnvType = string | undefined;

interface Endpoint {
  name: EnvType;
  endpoint: EnvType;
  region: EnvType;
}
interface AwsMobile {
  aws_project_region: EnvType;
  aws_cognito_identity_pool_id: EnvType;
  aws_cognito_region: EnvType;
  aws_user_pools_id: EnvType;
  aws_user_pools_web_client_id: EnvType;
  oauth: Record<string, EnvType>;
  aws_cloud_logic_custom: Endpoint[];
  aws_appsync_graphqlEndpoint: EnvType;
  aws_appsync_region: EnvType;
  aws_appsync_authenticationType: EnvType;
  aws_user_files_s3_bucket: EnvType;
  aws_user_files_s3_bucket_region: EnvType;
}

const awsmobile: AwsMobile = {
  aws_project_region: process.env.REACT_APP_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: process.env.REACT_APP_CLOUD_LOGIC_ADMINAPI_NAME,
      endpoint: process.env.REACT_APP_CLOUD_LOGIC_ADMINAPI_ENDPOINT,
      region: process.env.REACT_APP_CLOUD_LOGIC_ADMINAPI_REGION,
    },
    {
      name: process.env.REACT_APP_CLOUD_LOGIC_EMAILAPI_NAME,
      endpoint: process.env.REACT_APP_CLOUD_LOGIC_EMAILAPI_ENDPOINT,
      region: process.env.REACT_APP_CLOUD_LOGIC_EMAILAPI_REGION,
    },
  ],
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_APPSYNC_AUTHENTICATIONTYPE,
  aws_user_files_s3_bucket: process.env.REACT_APP_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_S3_BUCKET_REGION,
};

export default awsmobile;
