import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Layout,
  Typography,
  message,
} from 'antd';
import AppHeader from 'components/header';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
const { Title } = Typography;

const Login: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const handleLogin = async ({ email, password }: Auth) => {
    const emailDomain = email.split('@').pop();
    if (emailDomain === 'bondify.com') {
      try {
        setLoading(true);
        await Auth.signIn(email, password);
        setLoading(false);
        history.push('/');
      } catch (e) {
        setLoading(false);
        message.error('Login Failed');
      }
    } else {
      message.error('Unauthorized User');
    }
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <AppHeader path="/login" />
      <Row align="middle" justify="center" className="block-margin">
        <Col span={6}>
          <Title level={3} className="unit-margin">
            Back Office Login
          </Title>
          <Form name="login-form" layout="vertical" onFinish={handleLogin}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input a valid email' },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
