import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import Logo from './logo';

const { Header: AntdHeader } = Layout;

interface Props {
  path: string;
}

const Header: React.FC<Props> = ({ path }) => (
  <AntdHeader className="header__logo">
    <Link to={path}>
      <Logo />
    </Link>
  </AntdHeader>
);

export default Header;
