import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import AppHeader from 'components/header';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  AuditOutlined,
  BookOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import PrivateRoute from 'components/privateRoute';
import Login from './scenes/Auth/Login';
import Home from './scenes/Home/Home';
import WhiteList from './scenes/WhiteList/WhiteList';
import Requests from './scenes/Requests/Requests';
import { Auth } from 'aws-amplify';

const { Content, Sider, Footer } = Layout;

const pathToKey: Record<string, string> = {
  '/': '1',
  '/requests': '2',
  '/white-list': '3',
  '/companies': '4',
};

export default (): JSX.Element => {
  const mapUrlToKey = () => {
    return pathToKey[window.location.pathname];
  };

  return (
    <Router>
      <Route path="/login">
        <Login />
      </Route>
      <Layout>
        <AppHeader path="/" />
        <Content style={{ padding: '0 50px' }}>
          <Layout
            className="site-layout-background"
            style={{ padding: '24px 0' }}
          >
            <Sider className="site-layout-background" width={300}>
              <Menu
                mode="inline"
                style={{ height: '100%' }}
                defaultSelectedKeys={[mapUrlToKey()]}
              >
                <Menu.Item key="1" icon={<HomeOutlined />}>
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<AuditOutlined />}>
                  <Link to="/requests">Manage Pending org. Requests</Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<BookOutlined />}>
                  <Link to="/white-list">Manage Whitelist</Link>
                </Menu.Item>
                <Menu.Item
                  onClick={async () => {
                    await Auth.signOut();
                  }}
                  key="10"
                  icon={<LogoutOutlined />}
                >
                  <Link to="/login">Logout</Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: '90vh' }}>
              <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/white-list" component={WhiteList} />
                <PrivateRoute exact path="/requests" component={Requests} />
              </Switch>
            </Content>
          </Layout>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          © {new Date().getFullYear()} Bondify B.V
        </Footer>
      </Layout>
    </Router>
  );
};
