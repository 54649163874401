import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from '../aws-config';
import { Auth } from 'aws-amplify';

declare enum AuthType {
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
}

export const getCurrentUser = () => {
  const storedValue = localStorage.getItem('bondify') || '{}';
  const parsed = JSON.parse(storedValue);

  return parsed.userInfo;
};

const url = config.aws_appsync_graphqlEndpoint as string;
const region = config.aws_appsync_region as string;
const auth = {
  type: config.aws_appsync_authenticationType as AuthType.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken() as string,
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
