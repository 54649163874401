import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Redirect, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';

interface PrivateProps {
  component: React.ElementType;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateProps> = ({
  component: Component,
  ...rest
}) => {
  const [showComponent, setShowComponent] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      try {
        (await Auth.currentSession()).getIdToken().getJwtToken();
        setShowComponent(true);
      } catch (e) {
        setShowComponent(false);
      }
    })();
  }, []);

  if (showComponent === null) return <Spin />;

  return (
    <Route
      {...rest}
      render={(props) =>
        showComponent ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
