import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { GET_OPEN_REQUESTS } from 'graphql/queries';
import { Button, message, Popconfirm, Table } from 'antd';
import requestsMapper from 'services/requests-service';
import {
  CREATE_WHITELIST_DOMAIN,
  UPDATE_COMPANY,
  UPDATE_USER,
} from 'graphql/mutations';
import Users from 'components/users';
import { getCurrentUser } from 'utils/apollo';

const Requests = () => {
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { data, loading, refetch } = useQuery(GET_OPEN_REQUESTS);
  const [createDomain] = useMutation(CREATE_WHITELIST_DOMAIN);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [updateUser] = useMutation(UPDATE_USER);

  const onApprove = async (record: Request) => {
    setUpdateLoading(true);
    const userInfo = getCurrentUser();

    if (!userInfo) {
      message.error(`coudn't retrieve user information`);
      return;
    }

    const userId = userInfo.id;

    try {
      await Promise.all([
        createDomain({
          variables: {
            input: {
              domain: record.corporateDomain,
              description: record.companyName,
              blacklisted: false,
              domainNameAssociatedCompanyId: record.id,
              domainNameCreatedById: userId,
            },
          },
        }),
        updateCompany({
          variables: {
            input: {
              id: record.id,
              enabled: true,
            },
          },
        }),
        ...record.users.items
          .filter(
            (user: User) => user.onboardingStatus === 'PENDING_BONDIFY_APPROVAL'
          )
          .map((user: User) => {
            return updateUser({
              variables: {
                input: {
                  id: user.id,
                  onboardingStatus: 'COMPLETED',
                },
              },
            });
          }),
      ]);
      refetch();
    } catch (e) {
      message.error('Server Error');
    }

    setUpdateLoading(false);
  };

  const onReject = async (record: Request) => {
    setUpdateLoading(true);
    const userInfo = getCurrentUser();

    if (!userInfo) {
      message.error(`coudn't retrieve user information`);
      return;
    }

    const userId = userInfo.id;

    try {
      await Promise.all([
        createDomain({
          variables: {
            input: {
              domain: record.corporateDomain,
              description: record.companyName,
              blacklisted: true,
              domainNameAssociatedCompanyId: record.id,
              domainNameCreatedById: userId,
            },
          },
        }),
        ...record.users.items
          .filter(
            (user: User) => user.onboardingStatus === 'PENDING_BONDIFY_APPROVAL'
          )
          .map((user: User) => {
            return updateUser({
              variables: {
                input: {
                  id: user.id,
                  enabled: false,
                  onboardingStatus: 'COMPLETED',
                },
              },
            });
          }),
      ]);
      refetch();
    } catch (e) {
      message.error('Server Error');
    }

    setUpdateLoading(false);
  };

  const Columns = [
    {
      title: 'Organization Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Organization Domain',
      dataIndex: 'corporateDomain',
      key: 'corporateDomain',
    },
    {
      title: 'Organization Role',
      dataIndex: 'companyRole',
      key: 'companyRole',
    },
    {
      title: 'Organization Address',
      dataIndex: 'domain',
      key: 'domain',
      render: (_: string, { streetName, streetNumber, zipCode }: Request) => {
        return (
          <>
            <Text>{streetName}</Text>
            <Text className="unit-margin-sides">{streetNumber}</Text>
            <br />
            <Text>{zipCode}</Text>
          </>
        );
      },
    },
    {
      title: 'Registration Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => {
        const dateTimeFormat = Intl.DateTimeFormat('en', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        return dateTimeFormat.format(new Date(date));
      },
    },
    {
      title: 'Action',
      render: (_: string, record: Request) => {
        return (
          <>
            <Popconfirm
              placement="bottom"
              title="Please Confirm your choice"
              onConfirm={() => {
                onApprove(record);
              }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button type="primary">Approve</Button>
            </Popconfirm>
            <Popconfirm
              placement="bottom"
              title="Please Confirm your choice"
              onConfirm={() => {
                onReject(record);
              }}
              className="unit-margin-sides"
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button type="primary" danger>
                Reject
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Title level={3}>Manage Pending Requests</Title>
      <Table
        className="unit-margin"
        columns={Columns}
        dataSource={requestsMapper(data)}
        loading={loading || updateLoading}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Title className="unit-margin-uniform" level={4}>
                Pending Users
              </Title>
              <Users data={record.users.items} />
            </>
          ),
        }}
      />
    </>
  );
};

export default Requests;
