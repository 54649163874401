import React from 'react';
import Text from 'antd/lib/typography/Text';
import { Table, Tag } from 'antd';

interface Props {
  data: User[];
}

const UsersColumns = [
  {
    title: 'Name',
    key: 'name',
    render: (_: string, { firstname, lastname, permissions }: User) => {
      return (
        <>
          <Text>{`${firstname} ${lastname}`}</Text>
          <Tag
            className="unit-margin-sides"
            color={permissions.canAdministrate ? 'success' : 'default'}
          >
            {permissions.canAdministrate ? 'Admin' : 'Common'}
          </Tag>
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'onboardingStatus',
    key: 'onboardingStatus',
  },
  {
    title: 'Role',
    key: 'userRole',
    dataIndex: 'userRole',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    key: 'phone',
    dataIndex: 'phone',
  },
  {
    title: 'Registration Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date: string) => {
      const dateTimeFormat = Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      return dateTimeFormat.format(new Date(date));
    },
  },
];

const Users: React.FC<Props> = ({ data }) => {
  return (
    <Table
      bordered
      className="unit-margin-uniform"
      columns={UsersColumns}
      dataSource={data}
    />
  );
};

export default Users;
