import { gql } from '@apollo/client';

export const GET_DOMAINS_LIST = gql`
  query ListDomainNames($blackList: Boolean) {
    listDomainNames(limit: 200, filter: { blacklisted: { eq: $blackList } }) {
      items {
        id
        domain
        createdAt
        description
      }
    }
  }
`;

export const GET_OPEN_REQUESTS = gql`
  query ListCompany {
    listCompany(filter: { enabled: { eq: false } }) {
      items {
        id
        streetName
        companyName
        enabled
        companyRole
        corporateDomain
        corporateEmail
        streetNumber
        zipCode
        createdAt
        users(filter: { onboardingStatus: { ne: COMPLETED } }) {
          items {
            id
            firstname
            lastname
            onboardingStatus
            userRole
            permissions {
              canAdministrate
            }
            createdAt
            phone
            email
          }
        }
      }
    }
  }
`;

export const GET_REGISTERED_COMPANIES = gql`
  query ListCompany {
    listCompany(filter: { enabled: { eq: true } }) {
      items {
        id
        streetName
        companyName
        enabled
        companyRole
        corporateDomain
        corporateEmail
        streetNumber
        zipCode
        createdAt
        users(filter: { onboardingStatus: { ne: COMPLETED } }) {
          items {
            id
            firstname
            lastname
            onboardingStatus
            userRole
            permissions {
              canAdministrate
            }
            createdAt
            phone
            email
          }
        }
      }
    }
  }
`;
