import Title from 'antd/lib/typography/Title';
import React from 'react';

const Home = () => {
  return (
    <>
      <Title level={3} className="unit-margin">
        Home
      </Title>
      <Title level={5} className="unit-margin">
        You are logged In
      </Title>
    </>
  );
};

export default Home;
